import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardOut } from './guards/guard-out.guard';

const routes: Routes = [
  { path: '', canActivate: [GuardOut], loadChildren: './components/pages/login/login.module#LoginModule' },
  { path: 'auth', loadChildren: './auth_layout/auth.module#AuthModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterModule' },
  { path: 'login', canActivate: [GuardOut], loadChildren: './components/pages/login/login.module#LoginModule' },
  { path: 'create-password', loadChildren: './components/pages/create-password/create-password.module#CreatePasswordModule' },
  { path: 'otp', loadChildren: './components/pages/otp-page/otp-page.module#OtpPageModule' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
