// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  mailApi: '/assets/list.json',
  baseBackendUrl: 'https://distributionportal.chubblife.co.id/portal-api', // baseWebApiUrl

  baseSLQPrinted: 'https://birt.chubblife.co.id/birt/frameset?__report=acews/slq_letter.rptdesign&__title=SLQ%20Letter&nPage=0&p_slq=trackId&p_print_preview=1&__toolbar=false&__masterpage=true&__format=pdf',
  // baseBackendUrl: 'http://10.128.163.42:8080/portal-api' // server 1
  // baseBackendUrl: 'http://10.128.163.43:8080/portal-api' // server 2
};
export const palete = {
  primary: '#D32F2F',
  accent: '#E65100',
  warm: '#C2185B',
  name: '#D50000',
  secondary: '#D81B60',
  tertiary: '#8E24AA',
  quaternary: '#5E35B1',
  quinary: '#3949AB',
  secondaryLight: '#E91E63',
  tertiaryLight: '#9C27B0',
  quaternaryLight: '#673AB7',
  quinaryLight: '#3F51B5'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
