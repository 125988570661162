import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AajiExamUsersService } from 'src/app/services/aaji-exam-users-service/aaji-exam-users.service';
import { AasiExamUsersService } from 'src/app/services/aasi-exam-users-service/aasi-exam-users.service';
import { AajiExamUserTmService } from 'src/app/services/dmtm-service/aaji-exam-user-tm.service';
import { EFpkService } from 'src/app/services/e-fpk/e-fpk.service';
import { LeaderRecruitmentService } from 'src/app/services/leader-recruitment-service/leader-recruitment.service';
import { ManisService } from 'src/app/services/manis-service/manis.service';

@Component({
  selector: 'app-modal-upload-excel',
  templateUrl: './modal-upload-excel.component.html',
  styleUrls: ['./modal-upload-excel.component.scss']
})
export class ModalUploadExcelComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalUploadExcelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private manisService: ManisService,
    private leaderService: LeaderRecruitmentService,
    private efpkService: EFpkService,
    private aajiExamUsersService: AajiExamUsersService,
    private aasiExamUsersService: AasiExamUsersService,
    private aajiExamUsersTMService: AajiExamUserTmService,
  ) { }

  uploadForm: FormGroup;

  ngOnInit() {
    this.uploadForm = this.fb.group({
      excelFile: ['']
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('excelFile').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('uploadedFile', this.uploadForm.get('excelFile').value);

    if (this.data.type === "manis") {
      this.manisService.aajiUpload(formData).subscribe(data => {
        this.dialogRef.close({ status: 'success' });
      });
    } else if (this.data.type === "leader") {
      this.leaderService.aajiUpload(formData).subscribe(data => {
        this.dialogRef.close({ status: 'success' });
      });
    } else if (this.data.type === "efpk") {
      this.efpkService.aajiUpload(formData).subscribe(data => {
        this.dialogRef.close({ status: 'success' });
      });
    } else if (this.data.type === "aaji-exam") {
      this.aajiExamUsersService.upload(formData).subscribe(data => {
        this.dialogRef.close({ status: 'success' });
      });
    } else if (this.data.type === "aasi-exam") {
      this.aasiExamUsersService.upload(formData).subscribe(data => {
        this.dialogRef.close({ status: 'success' });
      });
    } else if (this.data.type === "aaji-exam-tm") {
      this.aajiExamUsersTMService.upload(formData).subscribe(data => {
        this.dialogRef.close({ status: 'success' });
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
