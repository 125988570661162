import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  ErrorStateMatcher, MatCardModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MAT_DATE_LOCALE, ShowOnDirtyErrorStateMatcher
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImgViewerModule } from './components/pages/img-viewer/img-viewer.module';
import { ModalAlertPageComponent } from './components/pages/modal-alert-page/modal-alert-page.component';
import { ModalAlertPageModule } from './components/pages/modal-alert-page/modal-alert-page.module';
import { ModalConfirmationComponent } from './components/pages/modal-confirmation/modal-confirmation.component';
import { ModalConfirmationModule } from './components/pages/modal-confirmation/modal-confirmation.module';
import { ModalFieldViewComponent } from './components/pages/modal-field-view/modal-field-view.component';
import { ModalLoadingComponent } from './components/pages/modal-loading/modal-loading.component';
import { ModalLoadingModule } from './components/pages/modal-loading/modal-loading.module';
import { ModalPopupComponent } from './components/pages/modal-popup/modal-popup.component';
import { ModalPopupModule } from './components/pages/modal-popup/modal-popup.module';
import { ModalWarningComponent } from './components/pages/modal-warning/modal-warning.component';
import { NewpasswordModule } from './components/pages/newpassword/newpassword.module';
import { SelectSearchModule } from './components/pages/select-search/select-search.module';
import { CoreModule } from './core/core.module';
import { GlobalDirectiveModule } from './directives/global-directives.module';
import { AuthChildGuard } from './guards/auth-child.guard';
import { AuthGuard } from './guards/auth.guard';
import { GuardOut } from './guards/guard-out.guard';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from './components/pages/login/login.module';
import { ModalUploadExcelComponent } from './components/pages/modal-upload-excel/modal-upload-excel.component';
import { ModalLoginLupaPasswordComponent } from './components/pages/modal-login-lupa-password/modal-login-lupa-password.component';
import { ModalSlqDetailComponent } from './components/pages/modal-slq-detail/modal-slq-detail.component';
import { ModalPpajStatusComponent } from './components/pages/modal-ppaj-status/modal-ppaj-status.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DEFAULT_TIMEOUT, TokenInterceptorService } from './core/token-interceptor.service';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    // ForgotpasswordSuccessComponent,
    ModalFieldViewComponent,
    ModalUploadExcelComponent,
    ModalLoginLupaPasswordComponent,
    ModalSlqDetailComponent,
    ModalPpajStatusComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    MatInputModule,
    // MatToolbarModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    // PdfViewerModule,
    LoginModule,
    // ForgotpasswordModule,
    ImgViewerModule,
    ModalAlertPageModule,
    ModalConfirmationModule,
    ModalLoadingModule,
    NewpasswordModule,
    ModalPopupModule,
    SelectSearchModule,
    PerfectScrollbarModule,
    GlobalDirectiveModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      },
    }),
    // MatTabsModule,
  ],
  providers: [
    AuthGuard, AuthChildGuard, GuardOut,
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }],
    // [{ provide: DEFAULT_TIMEOUT, useValue: 20000 }]
  ],
  entryComponents: [
    ModalWarningComponent,
    ModalConfirmationComponent,
    ModalAlertPageComponent,
    ModalPopupComponent,
    ModalFieldViewComponent,
    ModalLoadingComponent,
    ModalUploadExcelComponent,
    ModalLoginLupaPasswordComponent,
    ModalSlqDetailComponent,
    ModalPpajStatusComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
