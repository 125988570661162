import { Inject, Injectable, InjectionToken } from '@angular/core';

import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, timeout } from 'rxjs/operators';
import { AuthService } from '../services/auth-service/auth.service'
import { AppService } from '../services/app-service/app.service';
import { MatDialog } from '@angular/material';
import { ModalWarningComponent } from '../components/pages/modal-warning/modal-warning.component';
import { ERROR } from '../global-constants/http-error';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private _authService: AuthService,
        private appService: AppService,
        private dialog: MatDialog,
        // @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');

        if (this._authService.isAuthenticated()) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + token)
            });
            // const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
            // const timeoutValueNumeric = Number(timeoutValue);
            return next.handle(cloned)
            // .pipe(timeout(timeoutValueNumeric));
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403) {
                    this.onError(ERROR[error.status]);
                    setTimeout(() => {
                        this.appService.logout();
                        this.dialog.closeAll()
                    }, 1000)
                }
                return throwError(error);
            })
        );
    }

    onError(msg: string) {
        this.dialog.open(ModalWarningComponent, { data: { msg: msg } })
    }

}
