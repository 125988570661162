import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  confirmationType: string = '';
  titleMap: string = '';

  ngOnInit() {
    if (this.data.type == 'delete') {
      this.confirmationType =  'Delete Confirmation';
      this.titleMap = 'Are you sure to delete this ' + this.data.data + ' ?';
    } else if(this.data.type == 'save'){
      this.confirmationType =  'Save Confirmation';
      this.titleMap = 'Are you sure to save this ' + this.data.data + ' ?';
    } else if (this.data.type == 'cancel' && this.data.data == 'training') {
      this.confirmationType = 'Cancel Confirmation';
      this.titleMap = 'Are you sure to not join this training?';
    }
  }

  closeDialog(confirmation) {
    this.dialogRef.close(confirmation);
  }

}
