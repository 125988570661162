import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
    isOpen: boolean = false;

    // currentUser = null;
    Hari;


    @Input() currentUser = null;
    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }

        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }
    }

    dataUser: any = {};


    constructor(private elementRef: ElementRef, private router: Router, private appService: AppService) { }

    ngOnInit() {
        this.getUserData();
    }

    getUserData() {
        this.appService.getUserData({}).subscribe((res: any) => {

            const data = res.data;
            if (res.status == 'success') {
                if (data['loginAs'] == "AGENCY") {
                    if (data['m_user']) {
                        this.dataUser.role = data['m_user']['loginCategory'];
                    }
                    if (data['t_agent']) {
                        if (data['t_agent']['name']) {
                            this.dataUser.name = data['t_agent']['name'];
                        }
                    } else {
                        if (data['m_user']['name']) {
                            this.dataUser.name = data['m_user']['name'];
                        }
                    }
                } else if (data['loginAs'] == "TM") {
                    this.dataUser.name = data['tm_user']['name'];
                }
            }
        }, error => {
        });
    }

    logout() {
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    changePassword() {
        this.router.navigate(['/auth/change-password']);
    }

}
