import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/services/app-service/app.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ModalWarningComponent } from '../components/pages/modal-warning/modal-warning.component';
import { menus } from '../core/sidemenu/menu-element';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  currentUrl: string = '';
  menus;
  currentMenu: string = '';
  canAccessReportSOA: boolean = true;

  constructor(
    public auth: AuthService,
    public router: Router,
    private appService: AppService,
    public dialog: MatDialog
  ) {

    // DETECT ROUTER CHANGE
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log(val);
        if (!val.url.includes('login')) {
          if (!this.auth.isAuthenticated()) {
            this.appService.logout();
            this.onError('You\'re not authenticated!')
          } else {
            this.menus = menus;
            this.appService.getUserData({}).subscribe((res) => {
              let access = true;

              const data = res['data'];
              let privileges = [];
              if (res['status'] == 'success') {
                if (data['loginAs'] == "AGENCY") {
                  if (data['m_user']) {

                    data['m_user']['role']['privileges'].forEach(element => {
                      privileges.push(element['name']);
                    });
                  }
                } else if (data['loginAs'] == "TM") {
                  if (data['tm_user']) {
                    if (data['tm_user']['role']) {
                      data['tm_user']['role']['privileges'].forEach(element => {
                        privileges.push(element['name']);
                      });
                    }
                  }
                }
              }


              this.checker(this.menus, val.url);

              console.log(this.currentMenu);

              if (privileges.includes(this.currentMenu) || val.url.includes('dashboard')) {
                if(val.url.includes("tax-pph") || val.url.includes("statements-account")) {
                  console.log('current : ', val.url);
                  access = (data['m_user']['role']['name'] == 'DO Compensation' && data['m_user']['loginCategory'] == '02') || data['m_user']['loginCategory'] == '01';
                  console.log('accesss ? ', access);
                }
              }

              if (!access) {
                this.router.navigate(['/auth/dashboard']);
                this.onError('You don\'t have access to this menu!')
              }

            });
          }
        }
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isAuthenticated()) {
      this.appService.logout();
      return false;
    }

    return true
  }

  checker(entry, find) {
    let result = 'xd';
    for (let el of entry) {

      if (!el.link) {
        result = this.checker(el.sub, find);
      } else {
        if (el.link.includes(find)) {
          this.currentMenu = el.value;
          break;
        }
      }
    }
    return result;
  }

  onError(msg: string) {
    const dialogRef = this.dialog.open(ModalWarningComponent, { data: { msg: msg } })
    dialogRef.afterClosed().subscribe((res: any) => {
    })
  }
}
