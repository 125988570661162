import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PDFSource } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.type == 'pdf') {
      this.data.src as string | PDFSource
    } else {
      this.data.src as any
    }
  }

  totalPages: number;
  page: number = 1;
  isLoaded: boolean = false;
  zoomScl = 1

  nextPage() {
    this.page += 1;
  }

  previousPage() {
    this.page -= 1;
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  onClose() {
    this.dialogRef.close();
  }

  zoom(action) {
    if(action == 'in') {
      if(this.zoomScl < 1.2) {
        this.zoomScl += 0.1
      }
    } else {
      if(this.zoomScl > 1) {
        this.zoomScl -= 0.1
      }
    }
  }

}
