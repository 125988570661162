export const menus = [
    {
        name: 'Dashboard',
        icon: 'dashboard',
        link: '/auth/dashboard',
        open: false,
        value: 'dashboard'
    },
    {
        name: 'Inquiry Data',
        icon: 'assessment',
        link: false,
        open: false,
        value: 'Inquiry Data',
        sub: [
            {
                name: 'Polis',
                link: '/auth/inquiry/policy',
                chip: false,
                open: false,
                value: 'Inquiry Data - Daftar Polis'
            },
            {
                name: 'Polis Group',
                link: '/auth/inquiry/group-policy',
                chip: false,
                open: false,
                value: 'Inquiry Data - Daftar Polis Group'
            },
            {
                name: 'Polis Due Date',
                link: '/auth/inquiry/dueDate-policy',
                chip: false,
                open: false,
                value: 'Inquiry Data - Polis Due Date'
            },
            {
                name: 'Claim',
                link: '/auth/inquiry/claim-inquiry',
                chip: false,
                open: false,
                value: 'Inquiry Data - Claim'
            },
            {
                name: 'Premium Holiday',
                link: '/auth/inquiry/premium-holiday-inquiry',
                chip: false,
                open: false,
                value: 'Inquiry Data - Polis Premium Holiday'
            },
            {
                name: 'PPAJ',
                link: '/auth/inquiry/ppaj',
                chip: false,
                open: false,
                value: 'Inquiry Data - PPAJ'
            },

        ]
    },
    {
        name: 'Agent Profile',
        icon: 'sentiment_very_satisfied',
        link: false,
        open: false,
        value: 'Agent Profile',
        sub: [
            {
                name: 'Agent Profile Group',
                link: '/auth/agent-profile/group',
                chip: false,
                open: false,
                value: 'Agent Profile - Agent Profile Group'
            },
            {
                name: 'Agent Profile Individu',
                link: '/auth/agent-profile/individu',
                chip: false,
                open: false,
                value: 'Agent Profile - Agent Profile Individu'
            },
          {
            name: 'Agent Profile License AAJI',
            link: '/auth/agent-profile/license',
            chip: false,
            open: false,
            value: 'Agent Profile - Agent Profile Individu'
          }
        ]

    },
    {
        name: 'Bancassurance',
        icon: 'receipt',
        link: false,
        open: false,
        value: 'Bancassurance',
        sub: [
            {
                name: 'Is Profile',
                link: '/auth/bancassurance/is-profile',
                chip: false,
                open: false,
                value: 'Bancassurance - Is Profile',
            },
            {
                name: 'Sales Illusration/SII',
                link: false,
                chip: false,
                open: false,
                value: 'Bancassurance - Sales Illustration/SII',
                sub: [
                    {
                        name: 'Homepage',
                        link: '/auth/bancassurance/sii/homepage-sii',
                        chip: false,
                        open: false,
                        value: 'Bancassurance - Sales Illustration/SII',
                    }
                ]
            },

            {
                name: 'e-PPAJ',
                link: false,
                chip: false,
                open: false,
                value: 'Bancassurance - e-PPAJ',
                sub: [
                    {
                        name: 'Homepage',
                        link: '/auth/bancassurance/eppaj/homepage-eppaj',
                        chip: false,
                        open: false,
                        value: 'Bancassurance - e-PPAJ',
                    }
                ]
            },
            {
                name: 'Reports',
                link: false,
                chip: false,
                open: false,
                value: 'Bancassurance - Reports',
                sub: [
                    {
                        name: 'Is Production Report',
                        link: '/auth/bancassurance/reports/is-production-report',
                        chip: false,
                        open: false,
                        value: 'Bancassurance - Reports',
                    },
                    {
                        name: 'Unit Link Production Report',
                        link: '/auth/bancassurance/reports/unit-link-production',
                        chip: false,
                        open: false,
                        value: 'Bancassurance - Reports',
                    },
                    {
                        name: 'Credit Life Production Report',
                        link: '/auth/bancassurance/reports/credit-life-production',
                        chip: false,
                        open: false,
                        value: 'Bancassurance - Reports',
                    },
                    {
                        name: 'Leads',
                        link: '/auth/bancassurance/reports/report-leads',
                        chip: false,
                        open: false,
                        value: 'Bancassurance - Reports',
                    },
                ]
            },

        ]

    },

    {
        name: 'DMTM ',
        icon: 'featured_play_list',
        link: false,
        open: false,
        value: 'DMTM',
        sub: [
            {
                name: 'AAJI Exam',
                link: false,
                chip: false,
                open: false,
                value: 'DMTM - AAJI Exam',
                sub: [
                    {
                        name: 'AAJI Exam - Homepage',
                        link: '/auth/telemarketing/tm-aaji-exam',
                        chip: false,
                        open: false,
                        value: 'DMTM - AAJI Exam',
                        isStaff: false
                    },
                    {
                        name: 'AAJI Exam - Verification',
                        link: '/auth/telemarketing/tm-aaji-verification',
                        chip: false,
                        open: false,
                        value: 'DMTM - AAJI Exam',
                        isStaff: true
                    },
                    {
                        name: 'AAJI Exam - Edit',
                        link: '/auth/telemarketing/tm-aaji-edit',
                        chip: false,
                        open: false,
                        value: 'DMTM - AAJI Exam',
                        isStaff: false
                    },
                ]
            },

            {
                name: 'Commision Report',
                link: '/auth/telemarketing/commision-report',
                chip: false,
                open: false,
                value: 'DMTM - Commision Report'
            },
            {
                name: 'Producer Report',
                link: '/auth/telemarketing/producer-report',
                chip: false,
                open: false,
                value: 'DMTM - Producer Report'
            },
            {
                name: 'Success Billing Report',
                link: '/auth/telemarketing/sucessbilling-report',
                chip: false,
                open: false,
                value: 'DMTM - Success Billing Report'
            },
            {
                name: 'TM - Detail Information',
                link: '/auth/telemarketing/tm-detail-information',
                chip: false,
                open: false,
                value: 'DMTM - TM Detail Information'
            },
            {
                name: 'TM - Tree Hierarchy',
                link: '/auth/telemarketing/tm-tree_hierarchy',
                chip: false,
                open: false,
                value: 'DMTM - TM Tree Hierarchy'
            },
            {
                name: 'TM - Audit Trail',
                link: '/auth/telemarketing/tm-audit',
                chip: false,
                open: false,
                value: 'DMTM - TM Tree Hierarchy'
            },

        ]
    },

    {
        name: 'Agent Registration',
        icon: 'group_add',
        link: false,
        open: false,
        value: 'Agent Registration',
        sub: [
            {
                name: 'MANIS',
                link: '/auth/agent-registration/form-manis',
                chip: false,
                open: false,
                value: 'Agent Registration - MANIS'
            },
            {
                name: 'Rekrutmen Leader',
                link: '/auth/agent-registration/leader-recruitment',
                chip: false,
                open: false,
                value: 'Agent Registration - Rekrutmen Leader'
            },
            {
                name: 'eFPK',
                link: '/auth/agent-registration/efpk',
                chip: false,
                open: false,
                value: 'Agent Registration - eFPK',
            },
        ]
    },
    {
        name: 'Administration',
        icon: 'assignment',
        link: false,
        open: false,
        value: 'Administration',
        sub: [
            {
                name: 'Agent Process Report',
                link: '/auth/administration/agent-process-report',
                chip: false,
                open: false,
                value: 'Administration - Agent Process Report'
            },
            {
                name: 'Resend Verification Email',
                link: '/auth/administration/resend-verification-email',
                chip: false,
                open: false,
                value: 'Administration - Resent Registration Email'
            },
            {
                name: 'Reset Password Efpk',
                link: '/auth/administration/reset-password-efpk',
                chip: false,
                open: false,
                value: 'Administration - Resent Registration Email'
            },
            {
                name: 'SII Download',
                link: '/auth/administration/reset-password-sii',
                chip: false,
                open: false,
                value: 'Administration - Resent Registration Email'
            }

        ]
    },
    {
        name: 'Document',
        icon: 'folder_open',
        link: false,
        open: false,
        value: 'Document',
        sub: [
            {
                name: 'Document Management',
                link: '/auth/document/management',
                chip: false,
                open: false,
                value: 'Document - Document Management'
            },
        ]
    },
    {
        name: 'AAJI / AASI',
        icon: 'receipt',
        link: false,
        open: false,
        value: 'AAJI/AASI',
        sub: [
            {
                name: 'AAJI',
                link: false,
                chip: false,
                open: false,
                value: 'AAJI/AASI - AAJI',
                sub: [
                    {
                        name: 'AAJI Homepage',
                        link: '/auth/exam/aaji-homepage',
                        // icon: 'list',
                        chip: false,
                        open: false,
                        value: 'AAJI/AASI - AAJI',
                    },
                    {
                        name: 'AAJI Edit',
                        link: '/auth/exam/aaji-edit',
                        chip: false,
                        open: false,
                        value: 'AAJI/AASI - AAJI',
                        isStaff: false,
                    },
                    {
                        name: 'Verifikasi AAJI',
                        link: '/auth/exam/aaji-upload',
                        chip: false,
                        open: false,
                        value: 'AAJI/AASI - AAJI',
                        isStaff: true,
                    },
                ]
            },
            {
                name: 'AASI',
                link: false,
                chip: false,
                open: false,
                value: 'AAJI/AASI - AASI',
                sub: [
                    {
                        name: 'AASI Homepage',
                        link: '/auth/exam/aasi-homepage',
                        chip: false,
                        open: false,
                        value: 'AAJI/AASI - AASI',
                    },
                    {
                        name: 'AASI Edit',
                        link: '/auth/exam/aasi-edit',
                        chip: false,
                        open: false,
                        value: 'AAJI/AASI - AAJI',
                        isStaff: false,
                    },
                    {
                        name: 'Verifikasi AASI',
                        link: '/auth/exam/aasi-upload',
                        chip: false,
                        open: false,
                        value: 'AAJI/AASI - AASI',
                        isStaff: true,
                    },
                ]
            },

        ]

    },
    {
        name: 'Contest',
        icon: 'directions_bike',
        link: false,
        open: false,
        value: 'Contest',
        sub: [
            {
                name: 'Contest Management',
                link: '/auth/contest/contest-management',
                chip: false,
                open: false,
                value: 'Contest - Contest Management'
            },
        ]
    },
    {
        name: 'Reports',
        icon: 'description',
        // link: '/auth/report',
        link: false,
        open: false,
        value: 'Reports',
        sub: [
            {
                name: 'Reports',
                link: '/auth/report',
                chip: false,
                open: false,
                value: 'Reports',

            },
          {
            name: 'Agent Summary',
            link: '/auth/report/agent-summary',
            chip: false,
            open: false,
            value: 'Reports - Summary',

          },
            {
                name: 'Statements',
                link: false,
                chip: false,
                open: false,
                value: 'Reports',
                sub:[
                    {
                        name: 'Statements of Account',
                        link: '/auth/report/statements-account-statements',
                        chip: false,
                        open: false,
                        value: 'Reports',
                    },
                    {
                        name: 'Tax (PPh 21)',
                        link: '/auth/report/tax-pph-statements',
                        chip: false,
                        open: false,
                        value: 'Reports',
                    }
                ]
            },
            {
                name: 'Commission Report',
                link: false,
                chip: false,
                open: false,
                value: 'Reports',
                sub:[
                    {
                        name: 'Statements of Account',
                        link: '/auth/report/statements-account-commission',
                        chip: false,
                        open: false,
                        value: 'Reports',
                    },
                    {
                        name: 'Tax (PPh 21)',
                        link: '/auth/report/tax-pph-commission',
                        chip: false,
                        open: false,
                        value: 'Reports',
                    }
                ]
            },
        ]
    },
    {

        name: 'E-Learning',
        icon: 'supervised_user_circle',
        link: false,
        open: false,
        value: 'E-Learning',
        sub: [
            {
                name: 'Kalender Kegiatan',
                link: '/auth/elearning/kalender-event',
                chip: false,
                open: false,
                value: 'E-Learning - Kalender Kegiatan'
            },
            {
                name: 'E-Learning',
                link: '/auth/elearning/elearning-page/e-dashboard',
                chip: false,
                open: false,
                value: 'E-Learning - E-Learning'
            },
            {
                name: 'Aktivitas Kegiatan',
                link: '/auth/elearning/activities',
                chip: false,
                open: false,
                value: 'E-Learning - Aktivitas Kegiatan'
            },
            {
                name: 'Histori Pelatihan',
                link: '/auth/elearning/history-pelatihan',
                chip: false,
                open: false,
                value: 'E-Learning - Histori Pelatihan'
            },
        ]
    },
];
