import { Component, OnInit, Input } from '@angular/core';
import { menus } from './menu-element';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Input() iconOnly: boolean = false;
  public menus = menus;

  privileges = {};

  constructor(
  ) { }

  ngOnInit() {
  }

}
