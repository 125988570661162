import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManisService {


  private formManisBaseUrl = environment.baseBackendUrl + '/form-manis';

  private getFormManisUrl = this.formManisBaseUrl + '/get-data';
  private postFormManisUrl = this.formManisBaseUrl + '/add-form';
  private saveDraftManisUrl = this.formManisBaseUrl + '/save-draft';
  private getFormManisByIdUrl = this.formManisBaseUrl + '/get';
  private approveFormManisUrl = this.formManisBaseUrl + '/approved';
  private rejectFormManisUrl = this.formManisBaseUrl + '/rejected';
  private generateAgentCodeUrl = this.formManisBaseUrl + '/generate-agent-code';
  private generateBulkAgentCodeUrl = this.formManisBaseUrl + '/generate-bulk-agent-code';
  private bulkApprovedDoUrl = this.formManisBaseUrl + '/bulk-approved';
  private aajiUploadUrl = this.formManisBaseUrl + '/aaji-upload';


  constructor(private http: HttpClient) { }

  getFormManis() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getFormManisUrl, httpOptions);
  }

  createFormManis(formManis: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.postFormManisUrl, formManis, httpOptions);
  }

  saveDraftFormManis(id,formManis:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.saveDraftManisUrl + '/' + id,formManis, httpOptions);
  }

  getFormManisById(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getFormManisByIdUrl + '/' + id, httpOptions);
  }

  approveFormManisById(id: number, noKtpNotes : object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.approveFormManisUrl + '/' + id , noKtpNotes, httpOptions);
  }

  editDataFormManis(id: number, note: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.formManisBaseUrl + '/' + id + '/update', note, httpOptions);
  }

  rejectFormManisById(id: number, noKtpNotes : object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.rejectFormManisUrl + '/' + id, noKtpNotes, httpOptions);
  }

  updateAajiApproval(id, request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.formManisBaseUrl + '/' + id + '/set-aaji-approval', request, httpOptions);
  }

  generateAgentCode() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.generateAgentCodeUrl, {}, httpOptions);
  }

  generateOneAgentCode(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.generateAgentCodeUrl + '/' + id, {}, httpOptions);
  }

  generateBulkAgentCode(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.generateBulkAgentCodeUrl, id, httpOptions);
  }


  approvedBulkDO(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.bulkApprovedDoUrl, id, httpOptions);
  }

  updateDocumentById(id, request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.formManisBaseUrl + '/' + id + '/update-document', request, httpOptions);
  }

  aajiUpload(formData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.aajiUploadUrl, formData, httpOptions);
  }

  statusProcessing(status) {
    if (status === 1) {
      return 'Waiting Direct Leader Approval';
    } else if (status === 2) {
      return 'Waiting Indirect Leader Approval';
    } else if (status === 3) {
      return 'Waiting Verification DO';
    } else if (status === 4) {
      return 'Approved';
    } else if (status === 5) {
      return 'Rejected';
    } else if (status === 6) {
      return 'Edit Data';
    } else {
      return '';
    }
  }

  aajiStatusProcessing(status) {
    if (status === 0) {
      return 'On Progress';
    } else if (status === 1) {
      return 'Approved';
    } else if (status === 2) {
      return 'Rejected';
    }
  }
  regionProcessing(status) {
    return status;
  }
}
