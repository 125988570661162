import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ElearningService {
  private TRAINING_BASE_URL = environment.baseBackendUrl + '/elearning/training';
  private EXAM_BASE_URL = environment.baseBackendUrl + '/elearning/exam';

  private API = {
    TRAINING: {
      ONLINE_LIST: this.TRAINING_BASE_URL + '/online',
      OFFLINE_LIST: this.TRAINING_BASE_URL + '/offline',
      OFFLINE_LIST_FILTER: this.TRAINING_BASE_URL + '/offline/filter/',
      LOCATION_LIST: this.TRAINING_BASE_URL + '/locations',
      OFFLINE_SESSION_LIST: this.TRAINING_BASE_URL + '/offline/session',
      DETAIL: this.TRAINING_BASE_URL + '/detail',
      JOIN: this.TRAINING_BASE_URL + '/join',
      ONGOING: this.TRAINING_BASE_URL + '/ongoing',
      PROGRESS: this.TRAINING_BASE_URL + '/progress',
      SAVE_PROGRESS: this.TRAINING_BASE_URL + '/progress/save',
      ROADMAP: this.TRAINING_BASE_URL + '/roadmap',
      HISTORY: this.TRAINING_BASE_URL + '/history',
      HISTORY_FILTER: this.TRAINING_BASE_URL + '/history/filter',
      CHECK_UNFININSHED_TRAINING: this.TRAINING_BASE_URL + '/agent',
    },
    EXAM: {
      GET_EXAM: this.EXAM_BASE_URL + '/question',
      GET_EXAM_HIST: this.EXAM_BASE_URL + '/question/history',
      SAVE_EXAM: this.EXAM_BASE_URL + '/save',
      RESULT: this.EXAM_BASE_URL + '/result',
      FEEDBACK: this.EXAM_BASE_URL + '/feedback/question',
      SAVE_FEEDBACK: this.EXAM_BASE_URL + '/feedback/save',

    }
  }

  constructor(private http: HttpClient) { }

  saveFeedback(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.post(this.API.EXAM.SAVE_FEEDBACK, request, httpOptions);
  }

  getFeedbackQuestions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(this.API.EXAM.FEEDBACK, httpOptions);
  }

  saveExam(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.post(this.API.EXAM.SAVE_EXAM, request, httpOptions);
  }

  saveTrainingProgress(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.post(this.API.TRAINING.SAVE_PROGRESS, request, httpOptions);
  }

  getTrainingProgress(id: string, agentCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.TRAINING.PROGRESS}/${id}/${agentCode}`, httpOptions);
  }

  getExamHistory(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.EXAM.GET_EXAM_HIST}/${id}`, httpOptions);
  }

  getExamQuestionList(trainingId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.EXAM.GET_EXAM}/${trainingId}`, httpOptions);
  }

  getExamResult(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.EXAM.RESULT}/${id}`, httpOptions);
  }

  getTrainingOnlineList(agentCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.TRAINING.ONLINE_LIST}/${agentCode}`, httpOptions);
  }

  getTrainingOfflineList(agentCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.TRAINING.OFFLINE_LIST}/${agentCode}`, httpOptions);
  }

  getTrainingOfflineListFilter(request:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.post(this.API.TRAINING.OFFLINE_LIST_FILTER, request, httpOptions);
  }

  getTrainingLocations() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(this.API.TRAINING.LOCATION_LIST, httpOptions);
  }

  getTrainingSessionList(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.TRAINING.OFFLINE_SESSION_LIST}/${id}`, httpOptions);
  }

  getTrainingDetail(id: string, agentCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.TRAINING.DETAIL}/${id}/${agentCode}`, httpOptions);
  }

  joinTraining(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.post(this.API.TRAINING.JOIN, request, httpOptions);
  }

  getOngoingTrainingList(agentCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(`${this.API.TRAINING.ONGOING}/${agentCode}`, httpOptions);
  }

  getRoadmapList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(this.API.TRAINING.ROADMAP, httpOptions);
  }

  getTrainingHistory() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.get(this.API.TRAINING.HISTORY, httpOptions);
  }

  filterTrainingHistory(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    }
    return this.http.post(this.API.TRAINING.HISTORY_FILTER, request, httpOptions);
  }

  checkUnfinishedTraining(agentCode: string) {
    return this.http.get(`${this.API.TRAINING.CHECK_UNFININSHED_TRAINING}/${agentCode}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('tok;
      })
    })
  }


}
