import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilService } from 'src/app/services/util-service/util.service';

@Component({
  selector: 'app-modal-field-view',
  templateUrl: './modal-field-view.component.html',
  styleUrls: ['./modal-field-view.component.scss']
})
export class ModalFieldViewComponent implements OnInit {

  form: FormGroup = this.fb.group({
    notes: ['', [Validators.required]]
  });;

  constructor(
    public dialogRef: MatDialogRef<ModalFieldViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private util: UtilService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close({ value: this.form.value.notes, status:'submit' });
    } else {
      this.util.validateAllFormFields(this.form);
    }
  }

}
