import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LIST } from 'src/app/global-constants/ppaj-status';

@Component({
  selector: 'app-modal-ppaj-status',
  templateUrl: './modal-ppaj-status.component.html',
  styleUrls: ['./modal-ppaj-status.component.scss']
})
export class ModalPpajStatusComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalPpajStatusComponent>
  ) { }

  rows = [...LIST]

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }


}
