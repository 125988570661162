const PPAJ_ACCEPTED_BUT = (subject,action) => `PPAJ sudah diterima namun, setelah kami periksa masih ada ${subject} yang masih kurang dan harus ${action}. Seharusnya ada Remark mengenai ${subject} yang diperlukan`
export const LIST = [
  {
    status:'Incomplete Document',
    description: PPAJ_ACCEPTED_BUT('dokumen','dilengkapi')
  },
  {
    status:'Incomplete Data',
    description: PPAJ_ACCEPTED_BUT('data','dilengkapi')
  },
  {
    status:'Incorrect Data',
    description: PPAJ_ACCEPTED_BUT('data','diperbaiki')
  },
  {
    status:'Initial registration in progress',
    description: 'PPAJ sudah diregister didalam sistem namun masih belum bisa diproses lebih lanjut karena ada kekurangan seharusnya ada Remark mengenai data pending'
  },
  {
    status:'Initial registration completed',
    description: 'PPAJ sudah selesai diproses registernya'
  },
  {
    status:'Waiting For Data Entry',
    description: 'PPAJ menunggu untuk diproses data entry untuk memasukkan semua data yang ada diPPAJ'
  },
  {
    status:'Data Entry In Progress',
    description: 'Data PPAJ sudah diinput kedalam sistem namun masih belum bisa diproses lebih lanjut karena ada kekurangan. Seharusnya ada Remark mengenai data pending'
  },
  {
    status:'Waiting for verification',
    description: 'PPAJ menunggu untuk proses verify data yang sudah di input kedalam sistem'
  },
  {
    status:'Verification in progress',
    description: 'Data PPAJ sudah diverify namun masih belum bisa diproses lebih lanjut karena ada kekurangan. Seharusnya ada Remark mengenai data pending'
  },
  {
    status:'Verification in progress',
    description: 'Data PPAJ sudah diverify namun masih belum bisa diproses lebih lanjut karena ada kekurangan. Seharusnya ada Remark mengenai data pending'
  },
  {
    status:'Waiting for underwriting',
    description: 'PPAJ menunggu untuk diproses underwriting'
  },
  {
    status:'Underwriting in progressData',
    description: 'PPAJ sudah diunderwrite namun masih belum bisa diproses lebih lanjut karena ada kekurangan. Seharusnya ada Remark mengenai data pending'
  },
  {
    status:'Accepted',
    description: 'PPAJ sudah diapprove oleh underwriting dan menunggu pembayaran'
  },
  {
    status:'Conditionally Accepted',
    description: 'PPAJ sudah diapprove oleh underwriting dan menunggu pembayaran'
  },
  {
    status:'Declined',
    description: 'PPAJ tidak bisa diterima'
  },
  {
    status:'Postponed',
    description: 'PPAJ ditunda dahulu (belum bisa diterima untuk saat ini)'
  },
  {
    status:'Inforce',
    description: 'PPAJ yang sudah Issue'
  },
  {
    status:'Withdrawn',
    description: 'PPAJ yang dibatalkan'
  },
]
