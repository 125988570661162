import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewpasswordRoutingModule } from './newpassword-routing.module';
import { NewpasswordComponent } from './newpassword.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule, MatFormFieldModule, MatInputModule, MatGridListModule, MatIconModule, MatMenuModule, MatListModule, MatButtonModule, MatCardModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatOptionModule, MatSelectModule, MatButtonToggleModule, MAT_DATE_LOCALE, MatAutocompleteModule, MatIcon } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';



@NgModule({
  declarations: [NewpasswordComponent],
  imports: [
    CommonModule,
    NewpasswordRoutingModule,

    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatButtonModule,
    FormsModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatAutocompleteModule
  ]
})
export class NewpasswordModule { }
