import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaderRecruitmentService {
  private leaderBaseUrl = environment.baseBackendUrl + '/form-leader';

  private getLeaderDataURL = this.leaderBaseUrl + '/get-data';
  private saveDraftLeaderDataURL = this.leaderBaseUrl + '/save-draft';
  private getLeaderDataByIdURL = this.leaderBaseUrl + '/get';
  private postLeaderDataURL = this.leaderBaseUrl + '/add-form';
  private generateAgentCodeUrl = this.leaderBaseUrl + '/generate-agent-code';
  private aajiUploadUrl = this.leaderBaseUrl + '/aaji-upload';
  private generateBulkAgentCodeUrl = this.leaderBaseUrl + '/generate-bulk-agent-code';
  private bulkApprovedDoUrl = this.leaderBaseUrl + '/bulk-approved';

  constructor(private http: HttpClient) { }

  getLeaderRecData() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getLeaderDataURL, httpOptions);
  }

  createLeaderRecData(leaderForm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.postLeaderDataURL, leaderForm, httpOptions);
  }

  saveDraftLeaderRecData(id, leaderForm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.saveDraftLeaderDataURL + '/' + id, leaderForm, httpOptions);
  }

  getLeaderRecDataById(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getLeaderDataByIdURL + '/' + id, httpOptions);
  }

  approveLeaderRecData(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.leaderBaseUrl + '/' + id + '/approve', { 'update': { 'method': 'PUT' } }, httpOptions);
  }

  verifyLeaderRecData(id: number, request = {}) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.leaderBaseUrl + '/' + id + '/verifikasi', request, httpOptions);
  }

  editDataLeaderRecData(id: number, note: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.leaderBaseUrl + '/' + id + '/update', note, httpOptions);
  }

  rejectLeaderRecData(id: number, request = {}) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.leaderBaseUrl + '/' + id + '/reject', request, httpOptions);
  }

  updateDocumentById(id: any, request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.leaderBaseUrl + '/' + id + '/update-document', request, httpOptions);
  }

  generateBulkAgentCode(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.generateBulkAgentCodeUrl, id, httpOptions);
  }


  approvedBulkDO(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.bulkApprovedDoUrl, id, httpOptions);
  }

  updateAajiApproval(id, request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.leaderBaseUrl + '/' + id + '/set-aaji-approval', request, httpOptions);
  }

  generateAgentCode() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.generateAgentCodeUrl, {}, httpOptions);
  }

  generateOneAgentCode(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.generateAgentCodeUrl + '/' + id, {}, httpOptions);
  }

  aajiUpload(formData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.aajiUploadUrl, formData, httpOptions);
  }
}
