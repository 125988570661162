import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AajiExamUsersService {
  private baseUrl = environment.baseBackendUrl + '/aaji-exam-user';

  constructor(private http: HttpClient) { }

  private getDataUrl = this.baseUrl + "/get-data";
  private getDataByUserUrl = this.baseUrl + "/get-data/user";
  private verifyUrl = this.baseUrl + "/verify";
  private editUrl = this.baseUrl + "/edit";
  private rejectUrl = this.baseUrl + "/reject";
  private editSubmitUrl = this.baseUrl + "/edit-submit";
  private addNotesUrl = this.baseUrl + "/add-notes";
  private filterUrl = this.baseUrl + "/filter";
  private uploadUrl = this.baseUrl + "/upload";
  private onExamUrl = this.baseUrl + "/on-exam";
  private passExamUrl = this.baseUrl + "/pass-exam";

  getData() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getDataUrl, httpOptions);
  }

  getDataByUserId(userId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getDataByUserUrl + "/" + userId, httpOptions);
  }

  getOne(aajiExamid, userId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getDataUrl + "/" + aajiExamid + "/" + userId, httpOptions);
  }

  verify(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.verifyUrl, request, httpOptions);
  }

  reject(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.rejectUrl, request, httpOptions);
  }

  edit(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.editUrl, request, httpOptions);
  }

  editSubmit(id, request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.editSubmitUrl + "/" + id, request, httpOptions);

  }

  addNotes(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.addNotesUrl, request, httpOptions);

  }

  filterData(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.filterUrl, request, httpOptions);
  }

  filterDataByUserId(request, userId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.filterUrl + "/" + userId, request, httpOptions);
  }

  upload(formData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.uploadUrl, formData, httpOptions);
  }

  getIsPassExam(userId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.passExamUrl + "/" + userId, httpOptions);
  }
}
