import { Component,OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit{
  title = 'app';
  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  //new testing scrollTop
  constructor(private router: Router) { }
      
  ngOnInit() {
      this.router.events.subscribe((event) => {
          if (!(event instanceof NavigationEnd)) {
              return;
          }
          window.scrollTo(0, 0)
      });
  }

}
