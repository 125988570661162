import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatRadioModule
} from '@angular/material';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FlexLayoutModule } from '@angular/flex-layout';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalConfirmationComponent } from './modal-confirmation.component';

@NgModule({
  declarations: [ModalConfirmationComponent],
  imports: [
    MatCardModule,
      CommonModule,
      FlexLayoutModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatInputModule,
      MatToolbarModule,
      FormsModule,
      ReactiveFormsModule,
      MatIconModule,
      MatRadioModule,
      MatDialogModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})
export class ModalConfirmationModule { }
