import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { BranchListSelectComponent } from './branch-list-select/branch-list-select.component';
import { LeaderListSelectComponent } from './leader-list-select/leader-list-select.component';
import { IntroducerListSelectComponent } from './introducer-list-select/introducer-list-select.component';
@NgModule({
  declarations: [BranchListSelectComponent, LeaderListSelectComponent, IntroducerListSelectComponent],
  exports: [BranchListSelectComponent, LeaderListSelectComponent, IntroducerListSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    MatProgressSpinnerModule
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SelectSearchModule { }
