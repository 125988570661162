import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { scan } from 'rxjs/operators';

@Component({
  selector: 'app-introducer-list-select',
  templateUrl: './introducer-list-select.component.html',
  styleUrls: ['./introducer-list-select.component.scss']
})
export class IntroducerListSelectComponent implements OnInit {

  public searchCtrl: FormControl = new FormControl();
  public ctrl: FormControl = new FormControl();

  @ViewChild('singleSelect') singleSelect: MatSelect;

  @Input() hasIntroducer: boolean = false;
  @Input() showLoader: boolean = false;

  @Output() changeValue = new EventEmitter();
  @Output() changeSearch = new EventEmitter();


  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  subscriptions: Subscription[] = [];

  limit = 20;
  offset = 0;
  _options = new BehaviorSubject<any[]>([]);
  options$ = this._options.asObservable().pipe(
    scan((acc, curr) => {
      if (!acc || !curr) return [];
      return [...acc, ...curr];
    }, [])
  );

  data = [];
  @Input('data') data$;

  value = ""
  @Input('value') value$;

  constructor() { }

  ngOnInit() {
    this.subscriptions.push(this.data$.subscribe({
      next: (data) => {
        this.data = data;
        this.offset = 0;
        this._options.next(null);
        this.getNextBatch();
      }
    }));

    this.subscriptions.push(this.value$.subscribe({
      next: (data) => {
        this.ctrl.setValue(data);
      }
    }));
    this.subscriptions.push(
      this.searchCtrl.valueChanges.subscribe((val) => this.onSearchChange(this.searchCtrl.value))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onChange(e): void {
    this.changeValue.emit(e.value);
  }

  onSearchChange(e): void {
    this.changeSearch.emit(e);
  }

  getNextBatch() {
    const result = this.data.slice(this.offset, this.offset + this.limit);
    this._options.next(result);
    this.offset += this.limit;
  }

}
