import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-slq-detail',
  templateUrl: './modal-slq-detail.component.html',
  styleUrls: ['./modal-slq-detail.component.scss']
})
export class ModalSlqDetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalSlqDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = []
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
