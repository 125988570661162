import { Component, OnInit, Input } from '@angular/core';
import { id } from '@swimlane/ngx-charts';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
    selector: 'cdk-sidemenu-item',
    templateUrl: './sidemenu-item.component.html',
    styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent implements OnInit {

    @Input() menu;
    @Input() iconOnly: boolean;
    @Input() secondaryMenu = false;
    privileges: any;
    loginCategory: string;
    isDOComp: boolean = false;

    constructor() { }

    ngOnInit() {
        this.privileges = JSON.parse(localStorage.getItem('privileges'));
        this.loginCategory = localStorage.getItem('loginCategory');
        this.isDOComp = (localStorage.getItem('IS_DO_COMP') == '1');
    }

    openLink() {
        this.menu.open = this.menu.open;
    }

    chechForChildMenu() {
        return (this.menu && this.menu.sub) ? true : false;
    }

}
