import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
    MatProgressSpinnerModule,
    MatSidenavModule
} from '@angular/material';

import { ModalLoadingComponent } from './modal-loading.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSidenavModule
  ],
  declarations: [
    ModalLoadingComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})
export class ModalLoadingModule { }
