import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppService } from '../app-service/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public jwtHelper: JwtHelperService, private appService: AppService) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false
    }
    try {
      return !this.jwtHelper.isTokenExpired(token)
    } catch (e) {
      // handle the error, for eg.: 

      return false;
    }

  }

}
