import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAlertPageComponent } from './modal-alert-page.component';
import {
  MatCardModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatInputModule,
  MatToolbarModule,
  MatIconModule,
  MatRadioModule
} from '@angular/material';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    MatCardModule,
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule
  ],
  declarations: [
    ModalAlertPageComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})

export class ModalAlertPageModule { }
